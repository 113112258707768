import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Storage } from '@ionic/storage';
import { STORAGE_LOCATIONS } from '../../environments/environment';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(
        private userService: UserService,
        private router: Router,
        private storage: Storage,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const hasToken = await this.storage.get(STORAGE_LOCATIONS.userSession);

        if (hasToken) {
            await this.userService.getProfile().toPromise();
        }

        const loggedIn = !!this.userService.isAuthenticated();

        if (!loggedIn) {
            this.router.navigate(
                ['/iniciar-sesion'],
                { queryParams: { redirect: state.url.substring(1) } }
            );
        }

        return loggedIn;
    }
}
