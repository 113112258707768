/* eslint-disable max-len */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apirest: {
    base: 'https://admin.delivary.com.ar/api',
    orders: '/items/orders',
    shops: '/items/shops{{params}}?{{query}}',
    // Configs
    menuOptions: '/items/menu_options?single=1&fields=routes,menu_option_collections.collection,menu_option_collections.menu_option_field.*',
    // Users
    login: '/auth/authenticate',
    me: '/users/me?fields=*.*,avatar.data.*',
    users: '/users',
    requestPassword: '/auth/password/request',
    resetPassword: '/auth/password/reset',
    refreshToken: '/auth/refresh',
    requestCode: '/custom/verification/code/request',
    ttl: 1440 * 15,
  },
};

export const STORAGE_LOCATIONS = {
  fcmToken: 'fcm_session',
  refreshToken: 'session_expired',
  showTutorial: 'tutorial',
  tempEmail: 'tempEmail',
  tempPass: 'tempPass',
  userSession: 'userSession',
};

export const ERROR_CODES = {
  3: 'La sesión ha expirado. Vuelve a iniciar sesión para continuar.',
  12: 'El correo ya ha sido registrado.',
  100: 'Ups! Las credenciales son inválidas.',
  101: 'Ups! Las credenciales son inválidas.',
  102: 'Ups! La sesión ha expirado.',
  103: 'Ups! El usuario está inactivo.',
  104: 'Ups! El enlace es inválido.',
  105: 'Ups! El enlace ha expirado, debes solicitar uno nuevo.',
  106: 'Ups! El usuario no existe.',
  107: 'Ups! El correo ingresado no existe.',
  114: 'Ups! El correo o la contraseña son incorrectos.',
  203: 'Ups! No hemos encontrado lo que buscas.',
  302: 'Ups! No tienes permiso para realizar esa acción.',
  default: 'Ups! Algo no fue bien'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
