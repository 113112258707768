import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment, STORAGE_LOCATIONS } from '../../environments/environment';
import { MenuOptionsService } from './menu-options.service';

class State {
  isLogged: boolean;
  token: string;
  user: any;
  roleId: number;
  currentShop: {
    id: number;
  };
}


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private state = new State();
  private userSubject = new BehaviorSubject(null);
  constructor(
    private http: HttpClient,
    private storage: Storage,
    private router: Router,
    private navController: NavController,
    private menuOptions: MenuOptionsService,
  ) { }

  get user$(): Observable<any> {
    return this.userSubject.asObservable();
  }

  get user(): any {
    return this.state.user;
  }
  get roleId(): number {
    return this.state.roleId;
  }

  get currentShop() {
    return { id: 16 };
  }

  login(body) {
    return this.http.post<any>(environment.apirest.base + environment.apirest.login, body)
      .pipe(
        tap((resp) => {
          this.navController.setDirection('root');
          this.state.user = resp?.data?.user;
          this.state.roleId = Number(this.state.user.role);
          this.refreshSubject();
        }),
        switchMap((resp) => this.menuOptions.fetch(this.state.roleId)
          .pipe(map(() => resp))
        )
      );
  }

  isAuthenticated(): boolean {
    return !!(this.user && this.user.hasOwnProperty('id'));
  }

  signOut(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const fcmToken = this.storage.get(STORAGE_LOCATIONS.fcmToken);
      if (fcmToken) {
        try {
          // await this.apirestService.removeDevice({
          //   token: fcmToken,
          // });
        } catch (error) {
          console.log('error', error);
        }
      }

      await this.storage.remove(STORAGE_LOCATIONS.userSession);
      await this.storage.remove(STORAGE_LOCATIONS.fcmToken);
      await this.storage.remove(STORAGE_LOCATIONS.refreshToken);
      this.reset();
      this.navController.setDirection('root');
      this.router.navigateByUrl('/iniciar-sesion');
      resolve(true);
    });
  }

  requestPassword(email: string) {
    return this.http.post(environment.apirest.base + environment.apirest.requestPassword, { email }).toPromise();
  }

  resetPassword(token: string, password: string) {
    return this.http.post(environment.apirest.base + environment.apirest.resetPassword, { token, password }).toPromise();
  }

  async refreshToken() {
    const token = await this.storage.get(STORAGE_LOCATIONS.userSession);

    return this.http.post(environment.apirest.base + environment.apirest.refreshToken, { token }).toPromise();
  }

  getProfile() {
    return this.http.get<any>(environment.apirest.base + environment.apirest.me).pipe(
      tap((resp) => {
        this.state.user = resp?.data;

        this.state.roleId = Number(this.state.user.role.id);

        this.refreshSubject();
      }),
      switchMap((resp) => this.menuOptions.fetch(this.state.roleId)
        .pipe(map(() => resp))
      )
    );
  }

  reset() {
    this.state = new State();

    this.refreshSubject();
  }

  private refreshSubject() {
    this.userSubject.next(this.user || null);
  }
}
