import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, PreloadAllModules, Resolve, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { AuthGuardService } from './guards/auth.guard';
import { DataResolve } from './guards/menu-option.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'iniciar-sesion',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'inicio',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    data: { menuOption: true },
    canActivate: [AuthGuardService],
  },
  {
    path: 'pedidos',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule),
    canActivate: [AuthGuardService],
    data: { menuOption: 'orders' },
    resolve: { menuOption: DataResolve },
  },
  {
    path: 'reportes',
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsPageModule),
    canActivate: [AuthGuardService],
    data: { menuOption: 'reports' },
    resolve: { menuOption: DataResolve },
  },
  {
    path: 'productos',
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsPageModule),
    canActivate: [AuthGuardService],
    data: { menuOption: 'products' },
    resolve: { menuOption: DataResolve },
  },
  {
    path: 'pagos',
    loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsPageModule),
    canActivate: [AuthGuardService],
    data: { menuOption: 'payments' },
    resolve: { menuOption: DataResolve },
  },
  {
    path: 'tiempos-de-preparacion',
    loadChildren: () => import('./pages/preparation-times/preparation-times.module').then(m => m.PreparationTimesPageModule),
    canActivate: [AuthGuardService],
    data: { menuOption: 'preparation-times' },
    resolve: { menuOption: DataResolve },
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule),
    canActivate: [AuthGuardService],
    data: { menuOption: 'users' },
    resolve: { menuOption: DataResolve },
  },
  {
    path: 'tienda',
    loadChildren: () => import('./pages/store/store.module').then(m => m.StorePageModule),
    canActivate: [AuthGuardService],
    data: { menuOption: 'store' },
    resolve: { menuOption: DataResolve },
  },
  {
    path: 'notauthorized',
    loadChildren: () => import('./pages/notauthorized/notauthorized.module').then(m => m.NotauthorizedPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }