import { Component } from '@angular/core';
import { UserRol } from './interfaces/user';
import { MenuOptionsService } from './services/menu-options.service';
import { UserService } from './services/user.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  get appPages() {
    return this.menuOptions.pages;
  }
  constructor(
    private menuOptions: MenuOptionsService,
    private userService: UserService,
  ) { }
  logOut() {
    this.userService.signOut();
  }
}
