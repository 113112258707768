import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserRol } from '../interfaces/user';
class State {
  roleId: number;
  menuOptions: {
    routes: Array<string>;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    menu_option_collections: Array<any>;
  };
  pages: Array<any>;
}
@Injectable({
  providedIn: 'root'
})
export class MenuOptionsService {
  private state = new State();
  constructor(
    private http: HttpClient,

  ) { }

  get collectionsFields() {
    return {
      orders: [{
        key: 'id',
        label: 'ID',
        type: 'number'
      }, {
        key: 'status',
        label: 'Estado',
        type: 'string'
      }]
    };
  }

  get pages() {
    return this.state.pages;
  }

  private get pagesOptions() {
    return [
      { title: 'Inicio', url: '/inicio', icon: 'home', menuOption: 'home' },
      { title: 'Pedidos', url: '/pedidos', icon: 'receipt', menuOption: 'orders' },
      { title: 'Reportes', url: '/reportes', icon: 'bar-chart', menuOption: 'reports' },
      {
        title: 'Productos', url: '/productos', icon: 'restaurant', menuOption: 'products'
      },
      { title: 'Pagos', url: '/pagos', icon: 'card', menuOption: 'payments' },
      {
        title: 'Tiempos de preparación', url: '/tiempos-de-preparacion', icon: 'hourglass', menuOption: 'preparation-times'
      },
      { title: 'Usuarios', url: '/usuarios', icon: 'people', menuOption: 'users' },
      { title: 'Mi Tienda', url: '/tienda', icon: 'storefront', menuOption: 'store' },
    ];
  }

  fetch(roleId: number) {
    console.log('roleId', roleId, this.state.menuOptions);

    if (!roleId) {
      return;
    }
    // Caching Menu Options to prevent duplicate fetch in session.
    if (this.state.menuOptions && roleId === this.state.roleId) {
      return of(this.state.menuOptions);
    }

    this.state.roleId = roleId;

    return this.http.get<any>(environment.apirest.base + environment.apirest.menuOptions + '&filter[rol][eq]=' + roleId).pipe(
      tap((resp) => {
        this.state.menuOptions = resp.data;
        this.checkMenu(this.state.menuOptions.routes);
      })
    );
  }
  reset() {
    this.state = new State();

    this.refreshSubject();
  }
  private checkMenu(menuOptions) {
    this.state.pages = this.pagesOptions.filter(page => menuOptions.includes(page.menuOption));
  }

  private refreshSubject() {
    // this.userSubject.next(this.user || null);
  }
}
