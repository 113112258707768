import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { MenuOptionsService } from '../services/menu-options.service';

@Injectable({ providedIn: 'root' })
export class DataResolve implements Resolve<string> {
    constructor(
        private menuOptions: MenuOptionsService,
    ) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.menuOptions.pages.find(page => page.menuOption === route.data.menuOption) || false;
    }
}
