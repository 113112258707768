import { ERROR_CODES, STORAGE_LOCATIONS, environment } from '../../environments/environment';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    private token: string;
    constructor(
        private userService: UserService,
        private storage: Storage,
        private toastController: ToastController,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.storage.get(STORAGE_LOCATIONS.userSession))
            .pipe(
                switchMap(userToken => {
                    const isPrivate = this.isPrivateRequest(req.method, req.url);

                    if (isPrivate) {
                        const token = userToken || isPrivate.defaultToken || this.token;

                        if (token) {
                            req = req.clone({
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                setHeaders: { Authorization: `Bearer ${token}` },
                            });
                        }
                    }
                    return next.handle(req).pipe(
                        tap(
                            async (event) => {
                                const isLazySave = this.isLazySave(req.url);

                                if (event instanceof HttpResponse && isLazySave && isLazySave.key) {
                                    const resp = event.body.data;

                                    if (!resp.user) {
                                        await this.storage.set(isLazySave.key, resp);
                                    }

                                    if (resp.token && isLazySave.token) {
                                        const timestamp = (new Date()).getTime();
                                        this.token = resp.token;
                                        await this.storage.set(STORAGE_LOCATIONS.userSession, this.token);
                                        await this.storage.set(STORAGE_LOCATIONS.refreshToken, timestamp);
                                    }

                                    if (!resp.token && (await this.isValidToken() && await this.isPeriodToken())) {
                                        this.updateToken();
                                    }
                                }
                            },
                            async (error) => {
                                console.log('error 123');
                                if (error?.error?.error?.code === 3 || !error) {
                                    await this.doLogOut();
                                }
                            }
                        )
                    );
                })
            );
    }

    private isLazySave(url: string) {
        const routes = [{
            url: environment.apirest.login,
            key: STORAGE_LOCATIONS.userSession,
            token: true,
        }, {
            url: environment.apirest.refreshToken,
            key: STORAGE_LOCATIONS.userSession,
            token: true
        }];

        return routes.find(route => url.startsWith(environment.apirest.base + route.url));
    }

    private isPrivateRequest(method: string, url: string) {
        const routes = [{
            method: 'GET',
            url: environment.apirest.orders,
            defaultToken: null
        }, {
            method: 'GET',
            url: environment.apirest.me,
            defaultToken: null
        }, {
            method: 'GET',
            url: environment.apirest.menuOptions,
            defaultToken: null
        }, {
            method: 'PATCH',
            url: environment.apirest.users,
            defaultToken: null
        }];

        return routes.find(route => {
            let cleanRoute = route.url.match(/^(.*?)(?=\?|$)/)[0];
            cleanRoute = cleanRoute.replace(/{{(query|params)}}/g, '');

            const checkRouteMethod = this.checkRouteMethod(route.method, method);
            const urlStartsWith = url.startsWith(environment.apirest.base + cleanRoute);

            return checkRouteMethod && urlStartsWith;
        });
    }

    private checkRouteMethod(routeMethod: string | Array<string>, requestMethod: string) {
        if (Array.isArray(routeMethod)) {
            return routeMethod.includes(requestMethod);
        }

        return routeMethod === requestMethod;
    }

    private async isValidToken() {
        const oldTimestamp = Number(await this.storage.get(STORAGE_LOCATIONS.refreshToken));
        const ttlToken = environment.apirest.ttl;
        const expirationToken = new Date(oldTimestamp + ttlToken * 60000).getTime();

        return oldTimestamp < expirationToken;
    }

    private async isPeriodToken() {
        const oldTimestamp = Number(await this.storage.get(STORAGE_LOCATIONS.refreshToken));
        const ttlTokenAverage = environment.apirest.ttl / 3;
        const periodRefreshToken = new Date(oldTimestamp + ttlTokenAverage * 60000).getTime();

        return oldTimestamp - periodRefreshToken < 0;
    }

    private async doLogOut() {
        (await this.toastController.create({
            message: ERROR_CODES['3'],
            color: 'danger',
            buttons: [{ text: 'Aceptar', role: 'cancel' }],
            duration: 5000,
        })).present();

        this.userService.signOut();
    }

    private async updateToken() {
        const oldToken = this.storage.get(STORAGE_LOCATIONS.userSession);

        if (oldToken && this.isValidToken()) {
            await this.userService.refreshToken();
        } else {
            await this.doLogOut();
        }
    }
}
